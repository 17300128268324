* {
margin:0;
padding: 0;
box-sizing: border-box;
}
html{
  font-size: 62.5%;
}
body {
  background: rgb(2, 9, 42);
  color: white;
  font-family: 'Poppins', sans-serif;
}
.App {
 min-height: 100vh;
 display: flex ;
}

.sideBar {
  flex: 3;
  border-right: 1px solid rgb(62, 62, 62);
}


.upperSide {
  padding: 3rem;
  border-bottom: 2px solid rgb(100,100,100);
  height: 80%;
}

.upperSideTop {
display:flex;
align-items: center;
margin-bottom: 3rem;
}
.addBtn {
  height:2rem;
  padding-right: 1rem;
}

.midBtn {
 background: #134fd1;
 border:none;
 color:white;
 padding: 1.5rem;
 font-size: 1.5rem;
 width:25rem;
 display:flex;
 align-items: center;
 justify-content: center;
 margin: 0 auto;
margin-bottom: auto;
border-radius: 0.5rem;
}

.midBtn:hover {
  background-color: #1c4b87;
  color: white;
}

.query {
display:flex;
align-items: center;
background: transparent;
color: rgba(222,222,222,1);
padding:1.5rem;
width:25rem;
margin:1rem auto;
border-radius: 0.5rem;
border:1px solid rgba(98,98,98,1);
}

.query:hover {
  background-color: #456dfc;
  color: white;
}


.query>img {
  margin-right: 2rem;
  object-fit: cover;
  height: 1.5rem;
}

.lowerSide {
  padding: 1rem;  
}

.listItems {
 margin:0.75rem;
 display: flex;
align-items: center;
font-size: 1.3rem;
}
.listItemsImg {
margin: 1rem;
padding-right: 1rem;

}
.listItems:nth-child(1)>.listItemsImg {
  margin-right: 0.5rem;
}

.listItems:nth-child(3)>.listItemsImg {
  margin-right: 0.75rem;
}

.main {
flex: 9;  
min-height: calc(100vh-14rem);
display:flex;
flex-direction: column;
align-items: left;
margin: 2rem 1.0rem;
margin-bottom: 0rem;
}

.chats {
   overflow: hidden;
   overflow-y:auto;
   scroll-behavior: smooth;
   scrollbar-color:  rgb(2, 9, 42);
   width: 80%;
   max-width: 100rem;
   height: calc(100vh - 21rem);      
}

.chat {
 margin: 1rem;
 padding: 1.2rem 1.2rem;
 font-size: 1.2rem;
 display: flex;
 align-items: flex-start;
 text-align: justify;
}

.chatImg {
  object-fit: cover;
  width: 3.5rem;
  margin-right: 2rem;
  border-radius :0.5rem;
}
.bot {
  background:  rgb(2, 9, 42);
  width: fit-content;
  border-radius: 0rem ;
}
.chatFooter {
  margin-top: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inp {
  padding: 0rem;
  margin: 0rem;
  background: rgb(44, 50, 62);
  display: flex;
  align-items: left;
  border-radius: 0.5rem;
  width: 80rem;
}

.inp>input {
  width: calc(100% - 3rem);
  outline: none;
  padding: 2rem;
  color:rgb(255, 255, 255);
}

.inp>input, .send {
  background: transparent;
  border: none;
}
.chatFooter>p {
  margin: 2rem 0;
}

.txt {
  line-height: 2.4rem;
  color: rgb(255, 255, 255);
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  align-items: center;
  margin: 45px;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #58e4a7;
  color: #3ed7d2;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after  {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #80ccff;
  color: #80ccff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #80f0ff;
  color: #8080ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #80ccff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}


/* MyComponent.css */

.custom-dropdown-container {
    background: transparent;
    border: none;
  margin: 0px;
}

.custom-dropdown {
  width:  150px;
  padding: 3px;
  font-size: 10px;
  border: 1px solid #0c0c0c;
  border-radius: 2px;
  background-color: transparent; 
  color: #333;
  outline: none; /* Remove default focus border in some browsers */
}

.custom-dropdown:hover {
  border-color: #007bff;
}

.custom-dropdown:focus {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
}

.custom-dropdown-container>Select {
  width: calc(100% - 3rem);
  outline: none;
  padding: 1.25rem;
  color:rgb(7, 7, 7);
  background-color: transparent; 
}






/* The container */

.main1 { 
  min-height: 2rem;
  margin: 0px;
  }
.container {
  display: block;
  margin-left: 15px; 
  position: relative;
  padding-left: 35px; 
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 10%;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #1ccc7d;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 8px;
	left: 9px;
	width: 4px;
	height: 4px;
	border-radius: 8%;
	background: transparent;
}
/* text center */
.top-center-text {
 
  text-align: center;
  font-size: 17px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6vh;   
}
.txtcolor1 {
  color: #0fbc91;
  display: flex;
  align-items: center;
  justify-content: center;

}
.txtcolor2 {
  color: #7af3ce;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.logoimg {
  border-radius: 8px;
  border: 0px solid #ddd;
  border-radius: 7px;
  padding: 0px;
  width: 260px;

  margin-right: 1rem;
}

.logo
{
  margin-right: 1rem;
}
.brand
{
  font-size: 1.75rem;
  font-family: "Bahnschrift";
  text-shadow: 1px 1px 2px rgba(27, 118, 236, 0.922), 0 0 1em rgb(0, 174, 255), 0 0 0.2em rgb(11, 163, 169);
}
